import React, { useState, useEffect } from 'react'
import Footer from '../components/commonComponent/Footer'
import Header from '../components/commonComponent/Header'
import SideBar from '../components/commonComponent/SideBar'
import TradeModel from '../components/Trade/Model/TradeModel'
import TradeTable from '../components/Trade/TradeTable'
import { stockNews } from '../services/TradeService'
import { Link, useNavigate } from 'react-router-dom'
import OpenTrade from '../components/Home/Models/OpenTrade'
import { getPortfoloio } from '../services/PortfolioService'
import TradeCalculater from '../components/Home/Models/TradeCalculater'
import { getProviders } from '../services/ProviderServices'
import { getStrategy, getTradeSystemBYID } from '../services/StrategyService'
import { getCountryListAPI } from '../services/commonServices'
const Trade = () => {
    const [tradeCode,setTradeCode]=useState('')
    const [portfololioList,setPortfolioList]=useState([])
    const [providerList, setProviderList] = useState([])
    const [openTradeRun,setOpenTradeRun]=useState(true)
    const [calculatedData,setCalculatedData]=useState()
    const [runPortFolioAPI,setRunPortFolioAPI]=useState(true)
    const [calculatedTradeData,setCalculatedTradeData]=useState({})
    const [userType,setUserType]=useState('')
     const [strategyList,setStrategyList]=useState([])
    const [runAgainDashBoard,setRunAgainDashBoard]=useState(1)
    const [allCountryList, setAllCountryList] = useState([])
    useEffect(()=>{
        // let data={
        //     userId:'1',
        //     role:'user'
        // }
        // localStorage.setItem('userDetails',JSON.stringify(data))
      
        let nm=localStorage.getItem('userDetails')
        setUserType(JSON.parse(nm))
    },[])
    useEffect(() => {
        try {
            getPortfoloio(userType?.userId).then((res) => {
                if (res?.status === 200) {
                    setPortfolioList(res.data)
                    setRunPortFolioAPI(false)
                }
            })
        } catch (error) {
            
        }
    }, [runPortFolioAPI === true,userType?.userId])
    const navigate=useNavigate()
    const goBack = () => {
		navigate({
            pathname: '/',
          });
	}
    useEffect(() => {
        let data = {
            PageSize: 1000,
            PageNumber: 1
        }
        try {
            getProviders(data).then((res) => {
                if (res?.status === 200) {
                    setProviderList(res.data.data)
                }
            })
        } catch (error) {

        }

    }, [])
    useEffect(() => {
        let data={
            id:userType?.userId,
            pageNumber:1,
            pageSize:1000
        }
        try {
            getStrategy(userType?.userId).then((res) => {
                if (res?.status === 200) {
                    // console.log(res.data ,"startegy data")
                    setStrategyList(res.data)
                }
            })
        } catch (error) {

        }

    }, [])
    useEffect(() => {
		try {
			getCountryListAPI().then((res) => {
				if (res?.status === 200) {
					setAllCountryList(res.data)
				}
			})
		} catch (error) {

		}
	}, [])
    return (
        <>
            <section>
                <div className="container-fluid">
                {/* <Link to={'/'} search={`?role=${userType.role}&userId=${userType?.userId}`}> */}
                    <button type="button" className="btn-close trade_table_close" onClick={goBack} data-bs-dismiss="modal" aria-label="Close">×</button>
                    {/* </Link> */}
                    <div className="row">
                        {/* <SideBar /> */}
                        {/* <!--------ul-----clouse----> */}
                        <div className="col-lg-12 ">
                            {/* <Header/> */}
                            <TradeTable countryList={allCountryList} portfololioList={portfololioList} strategyList={strategyList} runAgainDashBoard={runAgainDashBoard} userType={userType} setCalculatedData={setCalculatedData} openTradeRun={openTradeRun} setOpenTradeRun={setOpenTradeRun} setTradeCode={setTradeCode} setCalculatedTradeData={setCalculatedTradeData}/>                    
                        </div>
                    </div>
                </div>
            </section>
            {/* <Footer /> */}

            {/* models */}
            <TradeModel tradeCode={tradeCode}/>
            <OpenTrade setRunAgainDashBoard={setRunAgainDashBoard} countryList={allCountryList} setOpenTradeRun={setOpenTradeRun} setCalculatedData={setCalculatedData} providerList={providerList} userType={userType} calculatedData={calculatedData} openTradeRun={openTradeRun} portfololioList={portfololioList}/>
            <TradeCalculater countryList={allCountryList} portfololioList={portfololioList} setCalculatedData={setCalculatedData} openTradeRun={openTradeRun} providerList={providerList}   userType={userType} calculatedTradeData={calculatedTradeData} setCalculatedTradeData={setCalculatedTradeData} />
        </>
    )
}

export default Trade