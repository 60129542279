import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { GetProviderstocks } from '../../../services/ProviderServices'
import { getStrategy } from '../../../services/StrategyService'
import { getSearchStockDetails } from '../../../services/HomeService'
import { BsFillArrowUpRightCircleFill, BsArrowDownRightCircleFill } from 'react-icons/bs'
import { Formik, Field, Form, useFormikContext } from 'formik'
import { toast } from 'react-toastify';
import { AddOpenTradeAPI } from '../../../services/TradeService'
import moment from 'moment/moment'
import CountryCurrencyList from '../../commonComponent/CountryCurrencyList'
import * as Yup from "yup";
import { getCommoditiPrice, getExchangeRateAPI, getStocks } from '../../../services/commonServices'
import process from 'process'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const ApplicationKey = process.env.REACT_APP_ApplicationKey;
let validationSchemaStopLoss = Yup.object().shape({
    PortfolioID: Yup.string().required('Portfolio is required'),
    ProviderID: Yup.string().required('Provider is required'),
    Strategy: Yup.string().required('Trading System is required'),
    StockCode: Yup.string().required('Commodities type is required'),
    TransFee: Yup.string().required('At Price is required'),
    ValueInShares: Yup.string().required('Value In Commodities is required'),
    quantity: Yup.string().required('Quantity is required'),
    StopLossPrice: Yup.string().required('Stop Price is required'),
});

let validationSchema = Yup.object().shape({
    PortfolioID: Yup.string().required('Portfolio is required'),
    ProviderID: Yup.string().required('Provider is required'),
    Strategy: Yup.string().required('Trading System is required'),
    StockCode: Yup.string().required('Commodities type  is required'),
    TransFee: Yup.string().required('At Price is required'),
    ValueInShares: Yup.string().required('Value In Commodities is required'),
    quantity: Yup.string().required('Quantity is required'),
});


const OpenTrade = ({ countryList, setOpenTradeRun, runAgainDashBoard, setRunAgainDashBoard, providerList, userType, portfololioList, openTradeRun, calculatedData, setCalculatedData }) => {

    const [checkStop, setCheckStop] = useState('1')
    const [tradeInput, setTradeInput] = useState({
        type: 'TRADES', tradeId: 0,
        Strategy: "", AtRiskDollar: 0, AtRiskPercent: 0, FromTransPriceDollar: 0,
        FromTransPricePercent: 0, GSLOTradingFee: 0, GSLOCost: 0, GSLOPrice: 0, StopLossPrice: '', Comments: '',
        ValueInShares: '', ValueInCFD: 0, TransCost: 0, TransFee: '', bid_size: 0, bid_price: 0, ask_size: '', ask_price: '',
        OpenDate: new Date(), Short: 'buy', Margin: '', Share_Sector: "Stock", StockName: '', StockCode: '', UserId: "",
        ProviderID: '', PortfolioID: '', quantity: '', brockerId: '', country: ''
    })

    const { TransFee, type, Short, OpenDate, StockName, PortfolioID, ProviderID, ValueInShares, quantity, StopLossPrice, Share_Sector, brockerId, Strategy, StockCode } = tradeInput
    const [loading, setLoading] = useState(false)
    const [checkInput, setCheckInput] = useState(false)
    const [cryptoSelectValue, setCryptoSelectValue] = useState()
    const [stockLists, setStockLists] = useState([])
    const [strategyList, setStrategyList] = useState([])
    const [cryptoDetails, setCryptodetails] = useState([])
    const [checkAskPrLo, setCheckAskPrLo] = useState('same')
    const [checkBidPrLo, setCheckBidPrLo] = useState('same')
    const [checkPricePrLo, setCheckPricePrLo] = useState('same')
    const [currencyCodes, setCurrencyCodes] = useState({ stockCountryCode: '', portfolioCountryCode: '', portfolioCurrency: '', stockCountrySymbol: '' })
    const { stockCountryCode, portfolioCountryCode, portfolioCurrency, stockCountrySymbol } = currencyCodes
    const [showCodeList, setShowCodeList] = useState(false)
    const [stockpageData, setStockPageData] = useState({
        id: '',
        PageNumber: 1,
        PageSize: 200,
        search: ''
    })
    const [brokerList, setBrokerList] = useState(portfololioList)
    const [exchangeRate, setExchangerate] = useState()
    // useEffect(() => {
    //     setTradeInput({ ...tradeInput, UserId: userType.userId })
    // }, [userType])


// console.log(tradeInput)
    useEffect(() => {
debugger
        let filteredProviderId
        if (calculatedData !== undefined && calculatedData !== '' && calculatedData.stockCode.length > 0) {
            let filteredProvider = providerList.filter((val) => val?.providerName == calculatedData?.providerName)
            // console.log(calculatedData ,"calculatedData")
            // console.log(countryList , "countrylist")
            let selectedStockCountry = countryList.filter((val) => val.value.toUpperCase() == calculatedData.country.toUpperCase())
            // console.log(selectedStockCountry ,"selectedStockCountry")
            let coinCountrySymbol = CountryCurrencyList.filter((val) => val.code == selectedStockCountry[0]?.value)
            setCurrencyCodes({ ...currencyCodes, portfolioCurrency: coinCountrySymbol[0].symbol_native })
            let aus = calculatedData.stockCode

            setCryptoSelectValue(calculatedData)
            setCryptodetails([...cryptoDetails, { s: s + 1, askPrice: calculatedData.ask_price, bidPrice: calculatedData.bid_price, price: calculatedData.last_trade?.price || 0, tradeType: calculatedData.last_trade?.taker_side || 'NA' }])

            setTradeInput({
                ...tradeInput,
                bid_size: calculatedData.bid_size,
                bid_price: calculatedData?.bid_price,
                ask_size: calculatedData.ask_size,
                ask_price: calculatedData?.ask_price,
                TransFee: calculatedData?.ask_price,
                tradeId: calculatedData.tradeId,
                type: calculatedData?.type || 'TRADES',
                StockCode: calculatedData.stockCode,
                Margin: calculatedData.margin,
                PortfolioID: calculatedData?.portFolioId,
                userId: userType?.userId,
                Strategy: calculatedData?.tradingSystemId,
                country: calculatedData?.country,
                ProviderID: filteredProvider[0]?.providerId,
                providerName: calculatedData?.providerName,
                StockName: aus,
                Share_Sector: "Stock",
                Short: calculatedData?.short,
                openDate: new Date(),
                quantity: calculatedData.quantity > 0 ? (calculatedData.quantity).toFixed(3) : '',
                ValueInShares: calculatedData?.quantity * calculatedData?.currentPrice || 0,
                transactionPrice: calculatedData?.currentPrice,
                StopLossPrice: calculatedData.stopLoss,
                // brockerId: (calculatedData?.providerId || filteredProviderId[0]?.provider?.providerId) + '-' + calculatedData?.portFolioId
            })
            // setInitialState({ ...initialState, transactionPrice: res.data[0]?.close, stockName: value.stockName, margin: value.margin, stockCode: value.stockCode })


            if (calculatedData.stopLoss > 0) {
                setCheckStop('1')
            }
            // if (calculatedData?.providerName != undefined) {
            //     let s = portfololioList.filter((val) => {
            //         if (val.provider.providerName == calculatedData?.providerName) {
            //             return val
            //         }
            //     })
            //     setBrokerList(s)
            //     if (s.length < 1) {
            //         toast.error('Broker not Available for this trade!')
            //     }
            // } else {
            //     setBrokerList(portfololioList)
            // }
        } else {
            // console.log(portfololioList ,"portfololioList")
            setBrokerList(portfololioList)
        }
    }, [calculatedData, portfololioList])
    // getProvider Stock List
    const [providerCurrency, setProviderCurrency] = useState()
    const getProviderStockList = (nm) => {
        nm.id = ProviderID
        try { 
            GetProviderstocks(nm).then((res) => {
              
                if (res?.status === 200) {
                    let data = res.data.data

                    setProviderCurrency(data[0].currencyBase)
                    // console.log(data[0].currencyBase, "provider stocklist")
                    setStockLists(data)

                }
            })
        } catch (error) {

        }
    }
    useEffect(() => {
        getProviderStockList(stockpageData)
    }, [ProviderID])
    const handleGetSequrityName = (e) => {
        const { scrollTop, clientHeight, scrollHeight } = e.target;
        if (
            (scrollTop + clientHeight) >= scrollHeight &&
            (scrollTop + clientHeight) <= scrollHeight + 100
        ) {
            let nm = stockpageData
            nm.PageSize = nm.PageSize + 100
            setStockPageData({ ...nm })
            if (nm.id !== undefined && stockLists.length >= nm.PageSize) {
                getProviderStockList(nm)
            }
        }
        // setInitialState({ ...initialState, stockName: e.target.value })
        // 
    }
    const [unit, setUnit] = useState()
    const [PortfolioCurrencySymbol, setPortfolioCurrencySymbol] = useState()
    const [stockprice, setStockPrice] = useState()

    const handleStockData = (value) => {
        // debugger
// console.log(value ,"dfdfdfdf")

        // let selectedStockCountry = countryList.filter((val) => val.key.toUpperCase() == value.country.toUpperCase())
        let selectedStockCountry = countryList.filter((val) => val.key.toUpperCase() === providerCurrency.toUpperCase());
        // let selectedStockCountry 
        // console.log(selectedStockCountry ,"selectedStockCountry")
        // console.log(CountryCurrencyList ,"CountryCurrencyList")
        let symbol = CountryCurrencyList.filter((val) => val.code == providerCurrency)
        // let symbol = CountryCurrencyList.filter((val) => val.code == selectedStockCountry[0]?.value)
        // let symbol
        // console.log(providerCurrency ,"providerCurrency")
        // console.log(symbol[0]?.symbol_native ,"symbol[0]?.symbol_native")
        // console.log(providerCurrency ,"provider currency")
        setCurrencyCodes({ ...currencyCodes, stockCountrySymbol: symbol[0]?.symbol_native, stockCountryCode: providerCurrency })
        // console.log(currencyCodes ,"currrencycodes")
        // setCurrencyCodes({ ...currencyCodes, stockCountrySymbol: symbol[0]?.symbol_native, stockCountryCode: selectedStockCountry[0]?.value })
        // console.log(currencyCodes, "dfdfdffdffdf")
        // console.log(currencyCodes.portfolioCurrency, "portfolio currency code")
        setPortfolioCurrencySymbol(currencyCodes.portfolioCurrency)
        setShowCodeList(false)
        let aus = value.forexSymbol
        // currencyCodes.portfolioCountryCode
        let comoditiData = {
            // base: currencyCodes.portfolioCountryCode,
            stockcode: aus
        }
        try {
            // getExchangeRateAPI(selectedStockCountry[0]?.value, currencyCodes?.portfolioCountryCode).then((resp) => {
            getCommoditiPrice(comoditiData).then((resp) => {
//  debugger
                // console.log(resp.data.unit, "response from api array value")
                // const exPrice = Number(resp.data["Realtime Currency Exchange Rate"]["5. Exchange Rate"]);
                // const exPrice = Number(resp.data.data[0].value)
                //setUnit(resp?.data)
                // setUnit({
                //     askPrice: resp?.data?.ask?.raw,
                //     bidPrice: resp?.data?.bid?.raw
                //   });
                // console.log(resp.data.bid.raw ,"fdfdfd")
                const bidPrice =  resp?.data?.bid?.raw
                // const bidPrice = 1 / resp.data.data.rates?.[aus]
                // const getaskPrice = Number(resp.data.data.rates?.[aus])
                const askPrice = resp?.data?.ask?.raw
                //const askPrice = 1 / resp.data.data.rates?.[aus]
                // setStockPrice(getaskPrice)

                // console.log(exchangeRate, askPrice, " exchangeRate ,askPrice")
                // const bidPrice = Number(respons.data["Realtime Currency Exchange Rate"]["8. Bid Price"]);
                // const askPrice = Number(respons.data["Realtime Currency Exchange Rate"]["9. Ask Price"]);
                // setExchangerate(exPrice)

                let data = {
                    askPrice: askPrice,
                    bidPrice: bidPrice
                }
                setCryptoSelectValue(data)
                // console.log (value , "value from opentrade")
                // debugger
                setTradeInput({
                    ...tradeInput,
                    bid_size: 0,
                    bid_price: bidPrice.toFixed(5),
                    ask_size: 0,
                    ask_price: askPrice.toFixed(5),
                    TransFee: (askPrice).toFixed(5),
                    StockCode: value.forexSymbol,
                    StockName: value.currencyGroup,
                    Margin: value.margin,
                    // country: value.country,
                    country: currencyCodes.portfolioCurrency,
                    ValueInShares: '',
                    quantity: '',
                    // portfolioCurrency :currencyCodes.portfolioCurrency ,
                    StopLossPrice: checkStop == '1' ? '' : 0
                })

                setExchangerate(1)
                debugger
                setCryptodetails([...cryptoDetails, { s: s + 1, askPrice: askPrice.toFixed(2), bidPrice: bidPrice.toFixed(2), price: 0, tradeType: 'NA' }])

            })
        } catch (error) {

        }


        // setInitialState({ ...initialState, transactionPrice: res.data[0]?.close, stockName: value.stockName, margin: value.margin, stockCode: value.stockCode })

    }
    // get trading system list
    const getTradingSystemList = () => {
        if (userType?.userId !== undefined) {
            try {
                getStrategy(userType?.userId).then((res) => {
                    if (res?.status === 200) {
                        // console.log(res.data, " strategy response")
                        let optionsStrategy = []
                        let data = res.data

                        for (let i = 0; i < data.length; i++) {
                            optionsStrategy.push({ value: data[i].tradingSystemId, label: data[i].name })
                        }
                        setStrategyList(data)
                        // setRunAgainbAPI(false)

                    }
                })
            } catch (error) {

            }
        }
    }
    useEffect(() => {
        getTradingSystemList()
    }, [userType])


    // get Crypto Details
    let s = 0
    // useEffect(() => {
    //     let n = cryptoDetails
    //     const getCryptoDetails = () => {
    //         try {
    //             getSearchStockDetails(StockCode).then((res) => {
    //                 if (res.status == 200) {
    //                     let data = res?.data



    //                     if (data.length > 0) {
    //                         setTradeInput({
    //                             ...tradeInput,
    //                             bid_size: res.data[0].bid_size,
    //                             bid_price: res.data[0].bid_price,
    //                             ask_size: res.data[0].ask_size,
    //                             ask_price: res.data[0].ask_price,
    //                             TransFee: res.data[0].ask_price,
    //                         })
    //                         n.push({ askPrice: data[1]?.ask_price, bidPrice: data[1]?.bid_price, price: data[1]?.last_trade.price })


    //                         setCryptodetails([...cryptoDetails, { s: s + 1, askPrice: data[1]?.ask_price, bidPrice: data[1]?.bid_price, price: data[1]?.last_trade.price }])


    //                         if (cryptoDetails.length > 4) {
    //                             cryptoDetails.splice(-1)
    //                         }

    //                     }
    //                 }
    //             })
    //         } catch (error) {

    //         }

    //     }
    //     const intervalId = setInterval(async () => {
    //         if (StockCode?.length > 0 && StockCode !== undefined && StockCode !== null) {
    //             getCryptoDetails()
    //         }
    //         // price gain or loose
    //         if (cryptoDetails[cryptoDetails.length - 1]?.price > cryptoDetails[cryptoDetails.length - 2]?.price) {
    //             setCheckPricePrLo('up')
    //         } else if (cryptoDetails[cryptoDetails.length - 1]?.price < cryptoDetails[cryptoDetails.length - 2]?.price) {
    //             setCheckPricePrLo('down')
    //         } else {
    //             setCheckPricePrLo('same')
    //         }
    //         // Ask price gain or loose
    //         if (cryptoDetails[cryptoDetails.length - 1]?.askPrice > cryptoDetails[cryptoDetails.length - 2]?.askPrice) {
    //             setCheckAskPrLo('up')
    //         } else if (cryptoDetails[cryptoDetails.length - 1]?.askPrice < cryptoDetails[cryptoDetails.length - 2]?.askPrice) {
    //             setCheckAskPrLo('down')
    //         } else {
    //             setCheckAskPrLo('same')
    //         }
    //         // Bid price gain or loose
    //         if (cryptoDetails[cryptoDetails.length - 1]?.bidPrice > cryptoDetails[cryptoDetails.length - 2]?.bidPrice) {
    //             setCheckBidPrLo('up')
    //         } else if (cryptoDetails[cryptoDetails.length - 1]?.bidPrice < cryptoDetails[cryptoDetails.length - 2]?.bidPrice) {
    //             setCheckBidPrLo('down')
    //         } else {
    //             setCheckBidPrLo('same')
    //         }
    //     }, 60000000); // set the interval time to 5 seconds (5000 milliseconds)  
    //     return () => clearInterval(intervalId);
    // }, []);

    const twoDecimal = (value) => {

        var roundedNumber = value;
        var decimalIndex = roundedNumber.indexOf(".");
        if (decimalIndex !== -1) {
            var decimalPlaces = roundedNumber.length - decimalIndex - 1;
            if (decimalPlaces > 5) {
                roundedNumber = roundedNumber.slice(0, decimalIndex + 6);
            }
        }
        return roundedNumber
    }


    const handleChengeTradeInput = (e) => {
        // console.log("clicked")
        debugger
        if (e.target.name == 'PortfolioID') {
            let portfolioSelected = portfololioList.filter((val) => e.target.value == val.portfolioId)
            let pValue = e.target.value
            setCurrencyCodes({ ...currencyCodes, portfolioCountryCode: portfolioSelected[0]?.currency, portfolioCurrency: portfolioSelected[0]?.currencySymbol })
            if (calculatedData !== undefined && calculatedData !== '') {
                let selectedStockCountry = countryList.filter((val) => val.key.toUpperCase() == calculatedData.country.toUpperCase())
                // console.log(StockCode ,"StockCodeStockCode")
                let stockCodeArr = StockCode
                try {
                    getExchangeRateAPI('USD', portfolioSelected[0]?.currency).then((respons) => {
                        // getExchangeRateAPI("WTI").then((respons) => {

                        try {
                            let comoditiData = {
                                //base: 'USD',
                                stockcode: stockCodeArr
                            }
                            // getExchangeRateAPI(selectedStockCountry[0]?.value, portfolioSelected[0]?.currency).then((res) => {
                            getCommoditiPrice(comoditiData).then((res) => {
                                debugger
                                const exPrice = Number(respons.data["Realtime Currency Exchange Rate"]["5. Exchange Rate"])
                                // const bidPrice = Number(respons.data["Realtime Currency Exchange Rate"]["8. Bid Price"]);
                                // const askPrice = Number(respons.data["Realtime Currency Exchange Rate"]["9. Ask Price"]);
                                // const exPrice = Number(res.data.data[0].value);
                                const bidPrice =  res?.data?.bid?.raw
                                const askPrice =  res?.data?.ask?.raw
                               // const bidPrice = res.data.data.rates?.[stockCodeArr];
                                //const askPrice = res.data.data.rates?.[stockCodeArr];
                                let data = {
                                    askPrice: askPrice,
                                    bidPrice: bidPrice
                                }
                                setCryptoSelectValue(data)
                                setExchangerate(exPrice)
                                setTradeInput({
                                    ...tradeInput,
                                    [e.target.name]: pValue,
                                    bid_price: calculatedData.bid_price,
                                    ask_price: calculatedData.ask_price,
                                    TransFee: calculatedData.ask_price,
                                    ValueInShares: calculatedData?.quantity || 0 * (calculatedData?.currentPrice || 0),
                                    transactionPrice: calculatedData?.currentPrice,
                                    StopLossPrice: calculatedData.stopLoss,
                                    // brockerId: (calculatedData?.providerId || filteredProviderId[0]?.provider?.providerId) + '-' + calculatedData?.portFolioId
                                })

                                setCryptodetails([...cryptoDetails, { s: s + 1, askPrice: Number(askPrice).toFixed(2), bidPrice: Number(bidPrice).toFixed(2), price: 0, tradeType: 'NA' }])
                            })
                        } catch (error) {

                        }
                    })
                } catch (error) {

                }
            } else {
                setTradeInput({
                    ...tradeInput,
                    [e.target.name]: e.target.value,
                    ValueInShares: '',
                    transactionPrice: 0,
                    StopLossPrice: checkStop == '1' ? '' : 0,
                    quantity: ''
                    // brockerId: (calculatedData?.providerId || filteredProviderId[0]?.provider?.providerId) + '-' + calculatedData?.portFolioId
                })
            }
        } else if (e.target.name == 'ProviderID') {
            if (PortfolioID == '') {
                toast.error('Select portfolio first.')
            } else {
                setCryptoSelectValue({})
                setStockPageData({
                    ...stockpageData, search: ''
                })
                setTradeInput({
                    ...tradeInput, [e.target.name]: e.target.value,
                    bid_size: '',
                    bid_price: '',
                    ask_size: '',
                    ask_price: '',
                    TransFee: '',
                    StockCode: '',
                    StockName: '',
                    Margin: '',
                    country: '',
                    ValueInShares: '',
                    StopLossPrice: checkStop == '1' ? '' : 0,
                    quantity: ''
                })

                setCryptodetails([...cryptoDetails, { askPrice: 0, bidPrice: 0, price: 0, tradeType: 'NA' }])
            }
        } else if (e.target.name == 'StockName') {
            // const staticStockCode = 'WTI';
            if (PortfolioID == '') {
                toast.error('Select portfolio first.')
            } else if (ProviderID == '') {
                toast.error('Select provider first.')
            } else {
                let nm = stockpageData
                let n = e.target.value.replace("/", "_")
                nm.search = n
                setTradeInput({ ...tradeInput, [e.target.name]: e.target.value })
                setShowCodeList(true)
                setStockPageData({
                    ...nm
                })
                if (nm.id !== undefined) {
                    getProviderStockList(nm)
                }
            }
        } else if (e.target.name == 'quantity') {
            if (Number(e.target.value) >= 0 || e.target.value == '.') {
                if (PortfolioID == '') {
                    toast.error('Select portfolio first.')
                } else if (ProviderID == '') {
                    toast.error('Select provider first.')
                } else if (StockCode.length == 0 || StockCode == '') {
                    toast.error('Select Commodities type first.')
                } else if (TransFee <= 0 || TransFee == '') {
                    toast.error('Enter at price first.')
                } else {
                    let roundedNumber = twoDecimal((e.target.value).toString())
                    let vs = (TransFee * exchangeRate * roundedNumber).toFixed(5)
                    // let vs = (TransFee    * roundedNumber).toFixed(2)
                    setTradeInput({
                        ...tradeInput, [e.target.name]: roundedNumber, ValueInShares: vs
                    })
                }
            }

        } else if (e.target.name == 'ValueInShares') {
            if (Number(e.target.value) >= 0 || e.target.value == '.') {
                if (PortfolioID == '') {
                    toast.error('Select portfolio first.')
                } else if (ProviderID == '') {
                    toast.error('Select provider first.')
                } else if (StockCode.length == 0 || StockCode == '') {
                    toast.error('Select Commodities type first.')
                } else if (TransFee <= 0 || TransFee == '') {
                    toast.error('Enter at price first.')
                } else {
                    let roundedNumber = twoDecimal(e.target.value)

                    // let qty = (roundedNumber / (TransFee * exchangeRate)).toFixed(0)
                    let qty = roundedNumber / TransFee
                    // console.log(TransFee, "TransFee")
                    // console.log(exchangeRate, "exchangeRate")
                    // console.log(qty, "quantity")
                    let n = qty
                    setTradeInput({
                        ...tradeInput, [e.target.name]: roundedNumber, quantity: n
                    })


                }
            }

        } else if (e.target.name == 'TransFee') {
            // console.log(exchangeRate , TransFee , "exchangerate and transfee" )
            if (Number(e.target.value) >= 0 || e.target.value == '.') {
                if (PortfolioID == '') {
                    toast.error('Select portfolio first.')
                } else if (ProviderID == '') {
                    toast.error('Select provider first.')
                } else if (StockCode.length == 0 || StockCode == '') {
                    toast.error('Select Commodities type first.')
                } else {
                    let roundedTrans = twoDecimal(e.target.value)
                    if (ValueInShares !== '' && ValueInShares !== undefined && ValueInShares !== 0) {
                        let qty = (ValueInShares / Number(roundedTrans) * exchangeRate).toFixed(2)
                        let n = qty
                        setTradeInput({
                            ...tradeInput, [e.target.name]: Number(roundedTrans), quantity: n, StopLossPrice: checkStop == '1' ? '' : 0
                        })


                    } else if (ValueInShares == '' && ValueInShares == undefined && ValueInShares == 0 && quantity !== '' && quantity !== undefined && quantity !== 0) {
                        let vs = quantity * Number(roundedTrans)
                        setTradeInput({
                            ...tradeInput, [e.target.name]: Number(roundedTrans), ValueInShares: vs
                        })
                    } else if (StockCode !== '') {
                        setTradeInput({
                            ...tradeInput, [e.target.name]: Number(roundedTrans)
                        })
                    }
                }
            } else if (e.target.value == '') {
                setTradeInput({ ...tradeInput, [e.target.name]: e.target.value })
            }
        } else if (e.target.name == 'StopLossPrice') {
            if (Number(e.target.value) >= 0 || e.target.value == '.') {
                if (PortfolioID == '') {
                    toast.error('Select portfolio first.')
                } else if (ProviderID == '') {
                    toast.error('Select provider first.')
                } else if (StockCode.length == 0 || StockCode == '') {
                    toast.error('Select Commodities type first.')
                } else if (TransFee <= 0 || TransFee == '') {
                    toast.error('Enter at price first.')
                } else {
                    let roundedNumber = twoDecimal(e.target.value)
                    if (Short == 'buy') {
                        if (TransFee > Number(roundedNumber)) {
                            setTradeInput({ ...tradeInput, [e.target.name]: roundedNumber })
                        } else {
                            toast.error('Set lower stop price than at price while buy trading')
                        }
                    } else {
                        setTradeInput({ ...tradeInput, [e.target.name]: roundedNumber })
                    }
                }
            }

        } else {
            setTradeInput({ ...tradeInput, [e.target.name]: e.target.value })
        }
    }

    const validationStoploss = () => {
        if (Short == 'sell') {
            if (TransFee >= Number(StopLossPrice)) {
                toast.error('Set higher stop price than at price while sell trading')
            }
        }
    }
    const handleChangeTradeType = (val) => {
        // debugger
        if (val === 'buy') {
            setTradeInput({
                ...tradeInput,
                TransFee: cryptoSelectValue?.askPrice || '',
                ValueInShares: '', quantity: '', StopLossPrice: '', Short: val
            })
        } else if (val === 'sell') {

            setTradeInput({
                ...tradeInput,
                TransFee: cryptoSelectValue?.bidPrice || '',
                ValueInShares: '', quantity: '', StopLossPrice: '', Short: val
            })
        }
        setCheckInput(false)
    }

    const openTradeFun = () => {
        // console.log("open")
        let apiData = {
            PortfolioID: Number(tradeInput.PortfolioID),
            userId: userType?.userId,
            Strategy: Number(tradeInput.Strategy),
            country: tradeInput.country,
            ProviderID: Number(tradeInput.ProviderID),
            StockCode: tradeInput.StockCode,
            StockName: tradeInput.StockName,
            Share_Sector: tradeInput.Share_Sector,
            Margin: (tradeInput.Margin).toString,
            Short: tradeInput.Short,
            OpenDate: tradeInput.OpenDate,
            tradeId: tradeInput.tradeId,
            type: tradeInput.type,
            quantity: (tradeInput.quantity).toString(),
            TransFee: Number(tradeInput.TransFee),
            TransCost: Number(tradeInput.TransCost),
            ValueInCFD: Number(tradeInput.ValueInCFD),
            ValueInShares: (tradeInput.ValueInShares).toString(),
            Comments: tradeInput.Comments,
            StopLossPrice: tradeInput.StopLossPrice,
            GSLOPrice: Number(tradeInput.GSLOPrice),
            GSLOCost: Number(tradeInput.GSLOCost),
            GSLOTradingFee: Number(tradeInput.GSLOTradingFee),
            FromTransPricePercent: Number(tradeInput.FromTransPricePercent),
            FromTransPriceDollar: Number(tradeInput.FromTransPriceDollar),
            AtRiskPercent: Number(tradeInput.AtRiskPercent),
            AtRiskDollar: Number(tradeInput.AtRiskDollar),
            bid_size: (tradeInput.bid_size).toString(),
            bid_price: (tradeInput.bid_price).toString(),
            ask_size: (tradeInput.ask_size).toString(),
            ask_price: (tradeInput.ask_price).toString(),
            ApplicationKey: ApplicationKey,
            // providerCurrency :   providerCurrency ,
            // stockCountrySymbol :stockCountrySymbol 
        }
        setLoading(true);
        try {
            AddOpenTradeAPI(apiData).then((res) => {
                if (res.status === 200) {
                    let optmClose = document.getElementById('openTradeModelCloseBtn')
                    optmClose.click()
                    // setOpenTradeRun(true)
                    setRunAgainDashBoard(runAgainDashBoard + 1)
                }
            }).finally(() => {
                setLoading(false);
            });
        } catch (error) {
            setLoading(false);
        }
    };

    const handletradeBuySell = () => {
        // debugger
        // console.log("handletradebuy")
        if (checkStop == '0') {
            // console.log(stockCountrySymbol , stockCountryCode , Share_Sector, PortfolioID, Strategy, StockCode, "stockCountrySymbol, stockCountryCode ,dsdsdShare_Sector,PortfolioID ,Strategy ,StockCode")
            if (TransFee !== '' && ValueInShares !== '' && quantity !== '' && Share_Sector !== '' && PortfolioID !== '' && StockCode !== '') {
                // console.log("more")
                setCheckInput(false)
                openTradeFun()
            } else {
                setCheckInput(true)
            }
        } else if (checkStop == '1') {
            // console.log("cdss")
            if (TransFee !== '' && ValueInShares !== '' && quantity !== '' && StopLossPrice !== '' && Share_Sector !== '' && PortfolioID !== '' && Strategy !== '' && StockCode !== '') {
                setCheckInput(false)
                openTradeFun()
            } else {
                setCheckInput(true)
            }
        }
    }
    const handleRemoveOpenData = () => {
        setCheckStop(0)
        setCryptodetails([...cryptoDetails, { s: 0, askPrice: 0, bidPrice: 0, price: 0, tradeType: 'NA' }])
        setCurrencyCodes({ stockCountryCode: '', portfolioCountryCode: '', portfolioCurrency: '' })
        setTradeInput({
            stockName: '', type: 'TRADES', tradeId: 0,
            Strategy: "", AtRiskDollar: 0, AtRiskPercent: 0, FromTransPriceDollar: 0, country: '',
            FromTransPricePercent: 0, GSLOTradingFee: 0, GSLOCost: 0, GSLOPrice: 0, StopLossPrice: '', Comments: '',
            ValueInShares: '', ValueInCFD: 0, TransCost: 0, TransFee: '', bid_size: 0, bid_price: 0, ask_size: '', ask_price: '',
            OpenDate: new Date(), Short: 'buy', Margin: '', Share_Sector: "Stock", StockName: '', StockCode: '', UserId: userType.userId,
            ProviderID: '', PortfolioID: '', quantity: '', brockerId: ''
        })
        setStockPageData({
            ...stockpageData, search: ''
        })
        setCryptoSelectValue({})
    }
    const handleBlurOpenTrade = () => {

    }
    const handleChangeStopLoss = (e) => {
        if (e.target.value == '0') {
            setTradeInput({
                ...tradeInput,
                StopLossPrice: 0,
                // brockerId: (calculatedData?.providerId || filteredProviderId[0]?.provider?.providerId) + '-' + calculatedData?.portFolioId
            })
        } else {
            setTradeInput({
                ...tradeInput,
                StopLossPrice: '',
                // brockerId: (calculatedData?.providerId || filteredProviderId[0]?.provider?.providerId) + '-' + calculatedData?.portFolioId
            })
        }
        setCheckStop(e.target.value)
    }
    const filterWeekends = (date) => {
        // Get the day of the week (0-6) for the provided date
        const day = date.getDay();
        // If the day of the week is Saturday (6) or Sunday (0), disable the date
        return day !== 0 && day !== 6;
    };
    // console.log(cryptoDetails)
    return (
        <>
            <div class="modal fade backdrop-1" id="openTrade" data-bs-backdrop="static" tabindex="-1" aria-labelledby="exampleModalLgLabel" aria-hidden="true" aria-modal="true" role="dialog" style={{ "display": "none;" }}>
                <div class="modal-dialog modal-lg mt-5 openTrade_model_size">
                    <div class="modal-content cal-model">
                        <div class="modal-header">
                            <h5 class="modal-title h4 white_text" id="openTradeLabel">Open Trade</h5>
                            <button type="button" className=" ms-auto button-padding" data-bs-dismiss="modal" id="openTradeModelCloseBtn" aria-label="Close" onClick={handleRemoveOpenData} >×</button>
                        </div>
                        <div class="modal-body">
                            {/* <!-- trs det --> */}
                            <Formik
                                initialValues={tradeInput}
                                enableReinitialize={true}
                                validationSchema={checkStop == '1' ? validationSchemaStopLoss : validationSchema}
                                onSubmit={(value) => {

                                    // alert(JSON.stringify(value))
                                    handletradeBuySell(value)
                                }}
                            >
                                {({ errors, touched, values }) => (
                                    <Form >

                                        <div className='row price-data-mr p-23-20 postion-min-dta border-0'>
                                            <div className='col-md-6 mb-2 white_text'>
                                                <label>Select Portfolio</label>
                                                <Field name='PortfolioID' value={PortfolioID} as='select' onBlur={handleBlurOpenTrade} onClick={() => setShowCodeList(false)} className={"form-select input_border"} onChange={handleChengeTradeInput}>
                                                    <option value="" style={{ visibility: "hidden;" }}>Select Portfolio</option>
                                                    {
                                                        brokerList?.map((val, ind) => {
                                                            return (
                                                                <option key={ind} value={val.portfolioId}>{val.portfolioName}</option>
                                                            )
                                                        })
                                                    }
                                                </Field>
                                                {errors.PortfolioID && touched.PortfolioID ? (
                                                    <div className='color-msg-err'>{errors.PortfolioID}</div>
                                                ) : null}
                                                {/* <Select className='' onChange={setProviderSelect} options={optionsBroker} isClearable /> */}
                                            </div>
                                            <div className='col-md-6 mb-2'>
                                                <label>Select Provider</label>
                                                <Field name='ProviderID' as='select' onBlur={handleBlurOpenTrade} onClick={() => setShowCodeList(false)} className={"form-select input_border"} onChange={handleChengeTradeInput}>
                                                    <option value="" style={{ visibility: "hidden;" }}>Select Provider</option>
                                                    {
                                                        providerList?.map((val, ind) => {
                                                            return (
                                                                <option key={ind} value={val.providerId}>{val.providerName}</option>
                                                            )
                                                        })
                                                    }
                                                </Field>
                                                {errors.ProviderID && touched.ProviderID ? (
                                                    <div className='color-msg-err'>{errors.ProviderID}</div>
                                                ) : null}
                                                {/* <Select className='' onChange={setProviderSelect} options={optionsBroker} isClearable /> */}
                                            </div>
                                            <div className='col-md-6 mb-2'>
                                                <label>Trade Type</label>
                                                <Field name='Share_Sector' as='select' value={Share_Sector} onClick={() => setShowCodeList(false)} onBlur={handleBlurOpenTrade} className={checkInput == true && Share_Sector == '' ? "form-select input_border input-err-color" : "form-select input_border"} onChange={handleChengeTradeInput}>
                                                    {/* <option value="0" style={{ visibility: "hidden;" }}>Select Share</option> */}
                                                    <option value="Stock" >Spot</option>
                                                    <option value="Sector" >Future</option>
                                                    <option value="CFDs" >CFDs</option>
                                                    <option value="Options" >Options</option>
                                                </Field>

                                            </div>

                                            <div className='col-md-6'>
                                                <label>Select Trading System</label>
                                                <Field name='Strategy' as='select' value={Strategy} onClick={() => setShowCodeList(false)} onBlur={handleBlurOpenTrade} className={"form-select input_border"} onChange={handleChengeTradeInput}>
                                                    <option value="" style={{ visibility: "hidden;" }}>Select Trading System</option>
                                                    {
                                                        strategyList?.map((val, ind) => {
                                                            return (
                                                                <option key={ind} value={val.tradingSystemId}>{val.name}</option>
                                                            )
                                                        })
                                                    }
                                                </Field>
                                                {/* {errors.Strategy && touched.Strategy ? (
                                                    <div className='color-msg-err'>{errors.Strategy}</div>
                                                ) : null} */}
                                            </div>
                                            <div className='col-md-6'>
                                                <label>Select Commodities Type</label>
                                                <Field name='StockName' type="select" onBlur={handleBlurOpenTrade} value={StockName} autoComplete="off" placeholder="select Commodities" className={"form-select input_border"} list="brow" onChange={handleChengeTradeInput} onClick={() => { setShowCodeList(!showCodeList) }} />
                                                {/* { */}
                                                {errors.StockCode && touched.StockCode ? (
                                                    <div className='color-msg-err'>{errors.StockCode}</div>
                                                ) : null}
                                                <div className='position-relative ' onBlur={() => setShowCodeList(false)}>
                                                    <div id='brow' className='p-10px' onScroll={handleGetSequrityName} style={{ display: (showCodeList === true && ProviderID != '') ? 'block' : 'none' }}>

                                                        {
                                                            stockLists?.map((val, ind) => {

                                                                // let stockArr = val.stockCode.split('_')

                                                                let stockArr = val.currencyGroup
                                                                {/* {console.log(val.stockCode,"stockArrssssssssssssssssssssssssssssssssssssssssssssssssssss")} */}
                                                                return (
                                                                    <p className='d-flex justify-content-between option_stock' key={ind} value={(val.stockCode)} onClick={() => handleStockData(val)}><span>{stockArr}</span></p>
                                                                )

                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                {/* <Select options={stockLists} onChange={setCryptoSelect} isClearable /> */}
                                            </div>
                                            <div className='col-md-6'>
                                                <label>Select type</label>
                                                <div className='d-flex justify-content-between'>

                                                    <div class="form-check ">
                                                        <input class="form-check-input tradeType" type="radio" value="" id="flexCheckCheckedDisabled" checked={type == 'TRADES'} onClick={() => setTradeInput({ ...tradeInput, type: 'TRADES' })} />
                                                        <label class="form-check-label" for="flexCheckCheckedDisabled">
                                                            TRADES
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input class="form-check-input tradeType" type="radio" value="" id="flexCheckCheckedDisabled" checked={type == 'TRENDS'} onClick={() => setTradeInput({ ...tradeInput, type: 'TRENDS' })} />
                                                        <label class="form-check-label" for="flexCheckCheckedDisabled">
                                                            TRENDS
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input class="form-check-input tradeType" type="radio" value="" id="flexCheckCheckedDisabled" checked={type == 'HODL'} onClick={() => setTradeInput({ ...tradeInput, type: 'HODL' })} />
                                                        <label class="form-check-label" for="flexCheckCheckedDisabled">
                                                            HODL
                                                        </label>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <label>Open Date</label>
                                                <DatePicker
                                                    className='date-picker ps-2'
                                                    selected={OpenDate}
                                                    onChange={(date) => setTradeInput({ ...tradeInput, OpenDate: date })}
                                                    minDate={new Date()}
                                                    filterDate={filterWeekends}
                                                />
                                                {/* {errors.Strategy && touched.Strategy ? (
                                                    <div className='color-msg-err'>{errors.Strategy}</div>
                                                ) : null} */}
                                            </div>
                                            <div class="col-lg-12 mt-3">
                                                <div class=" about-content-bx top-know-price-bx top-know-price-bx-1">
                                                    <div class="row">
                                                        <div class="col-lg-6">
                                                            <div class="know-prd-bx">
                                                                <h6>Ask </h6>
                                                                {/* {console.log(cryptoDetails ,"cryptoDetails")} */}
                                                                <span>{cryptoDetails[cryptoDetails.length - 1]?.askPrice || 0}</span>
                                                                {/* <span class={checkPricePrLo == 'up' ? "know-price-1 colorGreen" : checkPricePrLo == 'down' ? "know-price-1 colorRed" : "know-price-1"}>           {stockCountrySymbol} {cryptoDetails[cryptoDetails.length - 1]?.price || 0}        {checkPricePrLo == 'up' ? <BsFillArrowUpRightCircleFill /> : checkPricePrLo == 'down' ? <BsArrowDownRightCircleFill /> : ''}</span> */}
                                                                {/* <span class="float-end know-price-2"><img src="assets/image/down-aero.svg" class="img-fluid"/> 6.538</span>   */}
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <div class="know-prd-bx">
                                                                <h6>Bid </h6>

                                                                <span>{cryptoDetails[cryptoDetails.length - 1]?.bidPrice || 0}</span>
                                                                {/* <span class="float-end know-price-2 price-2-clr"><img src="assets/image/down-aero.svg" class="img-fluid"/> 773.53% </span>   */}
                                                            </div>
                                                        </div>
                                                        {/* <div class="col-lg-6">
                                                            <div class="know-prd-bx">
                                                                <h6>Exchange Rate</h6>

                                                                <span class="know-price-1">{exchangeRate}  </span>
                                                                
                                                            </div>
                                                        </div> */}



                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div class="col-lg-12">
                                                <div class="postion-min-dta postion-frm focusCss mt-1 tread-vol-bx border-redious-top-left">
                                                    <div class="row">
                                                        <div class="col-8  border-bottom">
                                                            <ul class="nav nav-pills " id="pills-tab" role="tablist">
                                                                <li class="nav-item" role="presentation">
                                                                    <button class={Short == 'buy' ? "nav-link active active-buy" : "nav-link"} onClick={() => handleChangeTradeType('buy')} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#buyTradeMain" type="button" role="tab" aria-controls="buyTrade" aria-selected="true">Buy</button>
                                                                </li>
                                                                <li class="nav-item " role="presentation">
                                                                    <button class={Short == 'sell' ? "nav-link active active-sell" : "nav-link"} onClick={() => handleChangeTradeType('sell')} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#sellTradeMain" type="button" role="tab" aria-controls="sellTrade" aria-selected="false">Sell</button>
                                                                </li>
                                                            </ul>

                                                        </div>
                                                        <div class="col-4">
                                                            <select type="text" value={checkStop} class="w-100 custom-select select-limit input_border" onChange={handleChangeStopLoss} id="formGroupExampleInput" placeholder="0" autocomplete="off">
                                                                <option value={'0'}>Limit</option>
                                                                <option value={'1'}>Stop Limit</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="tab-content" id="pills-tabContent">
                                                        <div class={Short == 'buy' ? "tab-pane fade show active" : "tab-pane fade"} id="buyTradeMain" role="tabpanel" aria-labelledby="pills-home-tab">
                                                            <div class="row">
                                                                <div class="">
                                                                    <div class=" mt-4">
                                                                        <div class="row">
                                                                            <div class="col-lg-6 mb-4">
                                                                                <div class={checkInput == true && TransFee == '' ? "know-commper-bx-min d-flex input-err-color " : "know-commper-bx-min d-flex "}>
                                                                                    <div>
                                                                                        <label for="formGroupExampleInput color-grey" class="form-label">At Price {stockCountrySymbol}</label>
                                                                                        <span className='border-2px'></span>
                                                                                    </div>
                                                                                    <input type="text" name='TransFee' onBlur={handleBlurOpenTrade} onClick={() => setShowCodeList(false)} class="form-control p-0 w-75 height-39 " value={TransFee} onChange={handleChengeTradeInput} id="formGroufpExampleInput" placeholder="0" autocomplete="off" />
                                                                                </div>
                                                                                {errors.TransFee && touched.TransFee ? (
                                                                                    <div className='color-msg-err'>{errors.TransFee}</div>
                                                                                ) : null}
                                                                            </div>

                                                                            <div class="col-lg-6 mb-4">
                                                                                <div class={"know-commper-bx-min d-flex"}>
                                                                                    <div>
                                                                                        <label for="formGroupExampleInput color-grey" class="form-label">Value In Commodities  {portfolioCurrency}</label>
                                                                                        <span className='border-2px'></span>
                                                                                    </div>
                                                                                    <input type="text" class="form-control p-0 w-50 height-39" onClick={() => setShowCodeList(false)} onBlur={handleBlurOpenTrade} name='ValueInShares' value={ValueInShares} onChange={handleChengeTradeInput} id="formGroupExampleInput" placeholder="0" autocomplete="off" />
                                                                                </div>
                                                                                {errors.ValueInShares && touched.ValueInShares ? (
                                                                                    <div className='color-msg-err'>{errors.ValueInShares}</div>
                                                                                ) : null}
                                                                                {/* <span><img src="assets/image/Bitmapindia-e1f7f5a05e7274c448d305e6d8482321.svg" class="img-fluid" /> INR </span> */}
                                                                            </div>
                                                                            <label class="unit" style={{ marginTop: -20, marginLeft: "4vw", color: "#ded8d8" }} >{unit}  </label>

                                                                        </div>
                                                                        <div class="row ">
                                                                            <div class="col-lg-6 mb-4">
                                                                                <div class={"know-commper-bx-min d-flex"}>
                                                                                    <div>
                                                                                        <label for="formGroupExampleInput color-grey" class="form-label">Quantity</label>
                                                                                        <span className='border-2px'></span>
                                                                                    </div>
                                                                                    <input type="text" class="form-control p-0 w-75 height-39 " onClick={() => setShowCodeList(false)} onBlur={handleBlurOpenTrade} name='quantity' value={quantity} onChange={handleChengeTradeInput} id="formGroupExampleInput" placeholder="0" autocomplete="off" />
                                                                                </div>
                                                                                {errors.quantity && touched.quantity ? (
                                                                                    <div className='color-msg-err'>{errors.quantity}</div>
                                                                                ) : null}
                                                                            </div>
                                                                            {
                                                                                checkStop == '1' &&
                                                                                <div class="col-lg-6 mb-4">

                                                                                    <div class={"know-commper-bx-min d-flex"}>
                                                                                        <div>
                                                                                            <label for="formGroupExampleInput color-grey" class="form-label">Stop Price {stockCountrySymbol}</label>
                                                                                            <span className='border-2px'></span>
                                                                                        </div>
                                                                                        <input type="text" class="form-control p-0 w-75 height-39" onClick={() => setShowCodeList(false)} onBlur={validationStoploss} name='StopLossPrice' value={StopLossPrice} onChange={handleChengeTradeInput} id="formGroupExampleInput" placeholder="0" autocomplete="off" />
                                                                                    </div>
                                                                                    {errors.StopLossPrice && touched.StopLossPrice ? (
                                                                                        <div className='color-msg-err'>{errors.StopLossPrice}</div>
                                                                                    ) : null}


                                                                                    {/* <span><img src="assets/image/Bitmapindia-e1f7f5a05e7274c448d305e6d8482321.svg" class="img-fluid" /> INR </span> */}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    {/* <div class="compare-bx mt-4 mb-4">
                                                                                <span class="comere-icon-bx">
                                                                                    <i class="bi bi-arrow-down-up"></i>
                                                                                </span>
                                                                            </div> */}

                                                                    <button class="btn col-lg-12 buyButton" type='submit' onClick={handletradeBuySell} disabled={loading}>Trade Buy</button>


                                                                </div>


                                                            </div>
                                                        </div>

                                                        <div class={Short == 'sell' ? "tab-pane fade show active" : "tab-pane fade"} id="sellTradeMain" role="tabpanel" aria-labelledby="pills-profile-tab">
                                                            <div class="row">
                                                                <div class="">
                                                                    <div class=" mt-4">
                                                                        <div class="row">
                                                                            <div class="col-lg-6 mb-4">
                                                                                <div class={checkInput == true && TransFee == '' ? "know-commper-bx-min d-flex input-err-color " : "know-commper-bx-min d-flex "}>
                                                                                    <div>
                                                                                        <label for="formGroupExampleInput color-grey" class="form-label">At Price  {stockCountrySymbol}</label>
                                                                                        <span className='border-2px'></span>
                                                                                    </div>
                                                                                    <input type="text" name='TransFee' onBlur={handleBlurOpenTrade} onClick={() => setShowCodeList(false)} class="form-control p-0 w-75 height-39 " value={TransFee} onChange={handleChengeTradeInput} id="formGroupExampleInput" placeholder="0" autocomplete="off" />
                                                                                </div>
                                                                                {errors.TransFee && touched.TransFee ? (
                                                                                    <div className='color-msg-err'>{errors.TransFee}</div>
                                                                                ) : null}
                                                                            </div>

                                                                            <div class="col-lg-6 mb-4">
                                                                                <div class={"know-commper-bx-min d-flex"}>
                                                                                    <div>
                                                                                        <label for="formGroupExampleInput color-grey" class="form-label">Value In Commodities  {portfolioCurrency}</label>
                                                                                        <span className='border-2px'></span>
                                                                                    </div>
                                                                                    <input type="text" class="form-control p-0 w-50 height-39" onClick={() => setShowCodeList(false)} onBlur={handleBlurOpenTrade} name='ValueInShares' value={ValueInShares} onChange={handleChengeTradeInput} id="formGroupExampleInput" placeholder="0" autocomplete="off" />
                                                                                </div>
                                                                                {errors.ValueInShares && touched.ValueInShares ? (
                                                                                    <div className='color-msg-err'>{errors.ValueInShares}</div>
                                                                                ) : null}
                                                                                {/* <span><img src="assets/image/Bitmapindia-e1f7f5a05e7274c448d305e6d8482321.svg" class="img-fluid" /> INR </span> */}
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col-lg-6 mb-4">
                                                                                <div class={"know-commper-bx-min d-flex"}>
                                                                                    <div>
                                                                                        <label for="formGroupExampleInput color-grey" class="form-label">Quantity</label>
                                                                                        <span className='border-2px'></span>
                                                                                    </div>
                                                                                    <input type="text" class="form-control w-75 p-0 height-39" onClick={() => setShowCodeList(false)} name='quantity' onBlur={handleBlurOpenTrade} value={quantity} onChange={handleChengeTradeInput} id="formGroupExampleInput" placeholder="0" autocomplete="off" />
                                                                                </div>
                                                                                {errors.quantity && touched.quantity ? (
                                                                                    <div className='color-msg-err'>{errors.quantity}</div>
                                                                                ) : null}
                                                                            </div>

                                                                            {
                                                                                checkStop == '1' &&
                                                                                <div class="col-lg-6 mb-4">

                                                                                    <div class={"know-commper-bx-min d-flex"}>
                                                                                        <div>
                                                                                            <label for="formGroupExampleInput color-grey" class="form-label">Stop Price {stockCountrySymbol}</label>
                                                                                            <span className='border-2px'></span>
                                                                                        </div>
                                                                                        <input type="text" class="form-control w-75 p-0 height-39" onClick={() => setShowCodeList(false)} onBlur={validationStoploss} name='StopLossPrice' value={StopLossPrice} onChange={handleChengeTradeInput} id="formGroupExampleInput" placeholder="0" autocomplete="off" />
                                                                                    </div>

                                                                                    {errors.StopLossPrice && touched.StopLossPrice ? (
                                                                                        <div className='color-msg-err'>{errors.StopLossPrice}</div>
                                                                                    ) : null}
                                                                                    {/* <span><img src="assets/image/Bitmapindia-e1f7f5a05e7274c448d305e6d8482321.svg" class="img-fluid" /> INR </span> */}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    {/* <div class="compare-bx mt-4 mb-4">
                                                                                <span class="comere-icon-bx">
                                                                                    <i class="bi bi-arrow-down-up"></i>
                                                                                </span>
                                                                            </div> */}


                                                                    <button class="btn col-lg-12 sellButton" type='submit' onClick={handletradeBuySell} disabled={loading}>Trade Sell</button>


                                                                </div>


                                                            </div>
                                                        </div>


                                                    </div>
                                                    {/* 
                                                            <div class=" about-content-bx top-know-price-bx">
                                                                <div class="know-commper-bx mt-5 mb-5">
                                                                    <div class="row">
                                                                        <div class="col-lg-6">
                                                                            <div class="know-commper-bx-min d-flex">
                                                                                <label for="formGroupExampleInput" class="form-label">Quantity</label>
                                                                                <span className='border-2'></span>
                                                                                <input type="text" class="form-control" id="formGroupExampleInput" placeholder="0" autocomplete="off" />
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="know-commper-bx-min d-flex">
                                                                                <label for="formGroupExampleInput" class="form-label">Quantity</label>
                                                                                <span className='border-2'></span>
                                                                                <input type="text" class="form-control" id="formGroupExampleInput" placeholder="0" autocomplete="off" />
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="compare-bx mt-4 mb-4">
                                                                    <span class="comere-icon-bx">
                                                                        <i class="bi bi-arrow-down-up"></i>
                                                                    </span>
                                                                </div>

                                                                <div class="know-commper-bx mt-5 mb-5">
                                                                    <div class="row">
                                                                        <div class="col-lg-8">
                                                                            <div class="know-commper-bx-min d-flex">
                                                                                <label for="formGroupExampleInput" class="form-label">Price</label>
                                                                                <input type="text" class="form-control" id="formGroupExampleInput" placeholder="6.544" />
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-4 pt-3">
                                                                            <span><img src="assets/image/Bitmapindia-e1f7f5a05e7274c448d305e6d8482321.svg" class="img-fluid" /> INR </span>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <button class="btn col-lg-12">Trade Now</button>


                                                            </div>
                                                            */}

                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                            {/* <!-- trs det --> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OpenTrade